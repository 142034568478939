@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+Display:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
.ant-modal.ant-modal-custom {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important; 
}

.ant-modal-custom .ant-modal-header, .ant-modal-custom .ant-modal-body, .ant-modal-custom .ant-modal-footer {
    background-color: #001529cc !important;
    border-bottom: 0;
    border-top: 0;
}

.ant-modal-custom .ant-modal-footer {
    display: none;
}

.ant-modal-custom .ant-modal-title, .ant-modal-custom .ant-col.ant-col-8.ant-form-item-label .ant-form-item-required {
    color: white;
}

.ant-modal-custom .ant-row.ant-row-space-between {
    margin-right: -194px !important
}

/* #profile-change-password label {
    color: #ddd;
} */
.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.15);
}
.ck-content ul {
  padding-left: 15px;
}
.ant-row {
  margin-bottom: 10px;
}
.ant-btn {
  font-weight: 400;
}
.ant-dropdown-menu {
  padding: 0;
}
.file-item:hover {
  text-decoration: underline;
}
.ant-select-tree-title {
  color: black;
}
.ant-row-pointer {
  cursor: pointer;
}
.ant-disabled {
  background: #ffffff !important;
  color: #000000d9 !important;
  cursor: not-allowed;
}
.ant-select-tree-node-content-wrapper-open .ant-select-tree-title {
  font-weight: bold;
}
.wrapper-list .ant-popover-inner-content {
  padding: 0;
}
.ant-select-item-option-content {
  white-space: unset;
  text-overflow: unset;
}
.action-btn-work {
  position: absolute;
  right: 0;
  margin-bottom: 5px;
}
.action-btn-work button {
  margin-left: 5px;
}
.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.MentionTextInput_mentions__1grDQ {
    background-color: #f0f2f5;
    border: 1px solid #dedbdb;
    border-radius: 8px;
}

.MentionTextInput_readonly__1N3HW {
    pointer-events: auto;
    border: none;
}

.MentionTextInput_mentions__1grDQ textarea {
    border: none;
}

.MentionTextInput_mentions--singleLine__3N09r .MentionTextInput_mentions__control__14GWV {
    display: inline-block;
    width: 130px;
}

.MentionTextInput_mentions--singleLine__3N09r .MentionTextInput_mentions__highlighter__16Ebh {
    padding: 1px;
    border: 2px inset transparent;
}

.MentionTextInput_mentions--singleLine__3N09r .MentionTextInput_mentions__input__1AmAe {
    padding: 1px;
    border: 2px inset;
}

.MentionTextInput_mentions--multiLine__4r9O0 .MentionTextInput_mentions__control__14GWV {
    /*font-family: monospace;*/
    font-size: 1.2em;
}

.MentionTextInput_mentions--multiLine__4r9O0 .MentionTextInput_mentions__highlighter__16Ebh {
    border: 1px solid transparent;
    padding: 9px;
    /*min-height: 63px;*/
}

.MentionTextInput_mentions--multiLine__4r9O0 .MentionTextInput_mentions__input__1AmAe {
    border: 1px solid transparent;
    padding: 9px;
    outline: 0;
}

.MentionTextInput_mentions__input__1AmAe textarea {
    border: none;
}

.MentionTextInput_mentions__suggestions__list__1xcj2 {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 10pt;
}

.MentionTextInput_mentions__suggestions__item__1Y3yx {
    padding: 5px 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.MentionTextInput_mentions__suggestions__item--focused__2L1m0 {
    background-color: #cee4e5;
}

.MentionTextInput_mentions__mention__125XO {
    position: relative;
    z-index: 1;
    color: blue;
    text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white,
    -1px -1px 1px white;
    text-decoration: underline;
    pointer-events: none;
}

.work-action-btn {
  display: inline-block;
  margin-left: 8px;
  float: right;
}

.target-item {
    background-color: #fff;
    transition: 0.4s;
    margin-bottom: 1px;
}

.header-target-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    grid-gap: 20px;
    gap: 20px;
    padding: 18px 0;
    margin: -12px 0;
}

.target-item:hover {
    background-color: #e9f0fd;
}

.sub-target-item {
    padding: 14px 16px 14px 40px;
    background-color: #fff;
    transition: 0.4s;
    /*cursor: pointer;*/
    border-bottom: 1px solid #f0f0f0;
}

.sub-target-item-row {
    display: flex;
    justify-content: space-between;
    grid-gap: 20px;
    gap: 20px;
    position: relative;
}

.sub-target-item .icon-star {
    color: #2f54eb;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -25px;
}

.sub-target-item:hover {
    background-color: #f8f9fb;
}

.header-kpi-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    padding: 12px 16px;
    background-color: #2c65ac;
    color: #fff;
    font-weight: 500;
}

.kpi-item {
    padding: 16px;
    border-radius: 6px;
    background-color: #f4f3f8;
    margin-bottom: 20px;
}

.title-kpi-list {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: #2c65ac;
    margin-bottom: 16px;
    text-transform: uppercase;
}

.title-kpi-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    margin-bottom: 10px;
}

.weight-number-kpi {
    flex: 0 0;
    width: 211px;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 42px;
    gap: 42px;
    font-weight: 500;
    /*background-color: #fff;*/
    /*padding: 5px;*/
    /*box-shadow: rgba(0, 0, 0, 0.1) 0 1px 3px 0, rgba(0, 0, 0, 0.06) 0 1px 2px 0;*/
    /*border-radius: 10px;*/
}

.summary-weight-value {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.target-edit-item {
    transition: 0.4s;
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    border: 1px solid #f0f0f0;
    border-top: none;
}

.target-edit-item:hover {
    background-color: #e9f0fd;
}

.header-target-list {
    display: flex;
    justify-content: space-between;
    grid-gap: 20px;
    gap: 20px;
    padding: 12px 16px;
    background-color: #2c65ac;
    color: #fff;
    font-weight: 500;
}

.weight-number-target {
    text-align: center;
    font-weight: 500;
}

.sub-target-edit-item {
    transition: 0.4s;
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    border: 1px solid #f0f0f0;
    border-top: none;
    background-color: #e9f0fd;
}

.work-edit-item {
    transition: 0.4s;
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    border: 1px solid #f0f0f0;
    border-top: none;
}

.work-edit-item:hover {
    background-color: #f8f9fb;
}

.weight-number-sub-target {
    text-align: center;
    font-weight: 500;
}

.customStyleText{
    font-style: italic;
    font-weight: 600;
    font-size: 14px;
}

.textValue {
    font-size: 16px;
}

.customStyle{
    margin-right: 8px;
}
.form-workchedule .ant-row {
    align-items: start !important;
}

.form-workchedule .ant-form-item .ant-select{
    width: 800px !important;
}
